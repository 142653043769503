/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/insights.module.scss";
import Header from "components/header";
import DataVignetteWrapper from "components/dataVignetteWrapper";
import { Alert, Avatar, Divider, Empty, Pagination } from "antd";
import Pie from "components/charts/pie";
import Line from "components/charts/line";
import { fetchInsights } from "utils/api";
import {
  INSIGHT_TYPES,
  RANKING_PAGE_SIZE,
  SEARCH_PARAMS_NAME,
  USER_RANKINGS,
} from "utils/constants";
import {
  slugify,
  transformActiveUsersData,
  transformCompanyValues,
  transformDataForLine,
  transformClapsExchangedData,
  transformTopUsersPerCompanyValues,
  useWindowSize,
  isSuperAdmin,
} from "utils/utils";
import Ranking from "components/charts/ranking";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { InfoCircleFilled, TrophyTwoTone } from "@ant-design/icons";
import ProfileContext from "components/profileContext";
import { shortnamesToUnicode } from "emojitsu";

const Insights = () => {
  const [rawData, setRawData] = useState(null);
  const [companyValues, setCompanyValues] = useState(null);
  const [topUsersCompanyValues, setTopUsersCompanyValues] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [clapsExchanged, setClapsExchanged] = useState(null);
  const [pageReceiver, setPageReceiver] = useState(1);
  const [pageGiver, setPageGiver] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = useWindowSize();
  const { profile } = useContext(ProfileContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile || !profile.isAdmin) {
      navigate("/");
    }
    fetchInsights(pageGiver, pageReceiver).then((data) => {
      const formatedData = data.reduce(
        (acc, element) => ({
          ...acc,
          [element.type]:
            element.params && element.params.type
              ? { ...acc[element.type], [element.params.type]: element.data }
              : element.data,
        }),
        {}
      );

      setRawData(formatedData);
      setCompanyValues(
        transformCompanyValues(formatedData[INSIGHT_TYPES.VALUES_USAGE])
      );
      setTopUsersCompanyValues(
        transformTopUsersPerCompanyValues(
          formatedData[INSIGHT_TYPES.TOP_USER_PER_VALUE]
        )
      );
      setActiveUsers(
        transformDataForLine(
          transformActiveUsersData(
            formatedData[INSIGHT_TYPES.ACTIVE_USERS_30D]
          ),
          "users"
        )
      );
      setClapsExchanged(
        transformDataForLine(
          transformClapsExchangedData(
            formatedData[INSIGHT_TYPES.CLAPS_EXCHANGED_LAST_WEEKS]
          ),
          "claps"
        )
      );
      setPageGiver(searchParams.get(SEARCH_PARAMS_NAME.PAGE_GIVER) || 1);
      setPageReceiver(searchParams.get(SEARCH_PARAMS_NAME.PAGE_RECEIVER) || 1);
      setLoading(false);
    });
  }, [pageGiver, pageReceiver]);

  const onChangePageGiver = (newPage) => {
    searchParams.set(SEARCH_PARAMS_NAME.PAGE_GIVER, newPage);
    setPageGiver(newPage);
    setSearchParams(searchParams);
  };
  const onChangePageReceiver = (newPage) => {
    searchParams.set(SEARCH_PARAMS_NAME.PAGE_RECEIVER, newPage);
    setPageReceiver(newPage);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContent}>
            <h1 className={styles.title}>Insights</h1>
            <div className={styles.subtitle}>
              Get analytics about your employee's engagement and Clappy's usage
            </div>
            <div className={styles.wrapper}>
              {/* <div className={styles.vignettes}>
                <NumberVignette
                  background="#d5eeff"
                  value={
                    rawData &&
                    rawData[INSIGHT_TYPES.CLAPS_EXCHANGED_ALL_TIME] &&
                    !loading
                      ? rawData[INSIGHT_TYPES.CLAPS_EXCHANGED_ALL_TIME]
                      : "-"
                  }
                  label={"All-time claps exchanged "}
                />
              </div> */}
              <div className={styles.dataWrapper}>
                <DataVignetteWrapper
                  // large
                  title={"Company Values"}
                  subtitle={"Which company values were used in recognitions"}
                >
                  {rawData &&
                    !loading &&
                    (companyValues && companyValues.length > 0 ? (
                      <>
                        <div className={styles.pieWrapper}>
                          <Pie
                            data={companyValues}
                            width={size.width < 1080 ? size.width - 72 : 460}
                            {...(size.width < 780
                              ? { simpleLabels: true }
                              : {})}
                            {...(companyValues.length > 10
                              ? { simpleLabels: true }
                              : {})}
                          />
                        </div>

                        <Alert
                          banner
                          type="info"
                          description="Tip: You can see this graph scoped on someone's profile by clicking on a user name in the Leaderboard section."
                          className={styles.hintAlert}
                          icon={
                            <InfoCircleFilled
                              style={{
                                color: "grey",
                                fontSize: "16px",
                                marginTop: "4px",
                              }}
                            />
                          }
                          showIcon
                        />
                      </>
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"No company values were used yet"}
                      />
                    ))}
                </DataVignetteWrapper>
                <DataVignetteWrapper
                  title={"Top employees per Company value"}
                  subtitle={"Discover top performers per value"}
                  isNew
                >
                  {rawData &&
                    !loading &&
                    (companyValues && companyValues.length > 0 ? (
                      <>
                        <br />
                        {topUsersCompanyValues.map((value, i) => (
                          <div key={value.name}>
                            <div className={styles.topUsersValueName}>
                              <div className={styles.topUsersValueNameInner}>
                                {shortnamesToUnicode(value.name)}
                              </div>

                              {!value.topUser && (
                                <span className={styles.topUserNoData}>
                                  Not enough data
                                </span>
                              )}
                              {value.topUser && (
                                <div>
                                  <Link
                                    to={`/leaderboards?type=received&value=${slugify(
                                      value.name
                                    )}&date=ALL_TIMES`}
                                    className={styles.TopUserViewLeaderboard}
                                  >
                                    View leaderboard
                                  </Link>
                                </div>
                              )}
                            </div>
                            {value.topUser && (
                              <div className={styles.topUserWrapper}>
                                <Avatar
                                  className={styles.topUsersAvatar}
                                  src={value.topUser.profilePicture}
                                  size={48}
                                />
                                <TrophyTwoTone
                                  twoToneColor="orange"
                                  className={styles.topUsersIcon}
                                />
                                <span className={styles.topValueUsername}>
                                  <Link to={`/users/${value.topUser._id}`}>
                                    {value.topUser.name}
                                  </Link>
                                </span>
                              </div>
                            )}
                            {i < topUsersCompanyValues.length - 1 && (
                              <Divider />
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"No company values were used yet"}
                      />
                    ))}
                </DataVignetteWrapper>
              </div>
              <Divider />
              <h2 className={styles.secondaryTitle}>Leaderboards</h2>
              <div className={styles.dataWrapper}>
                <DataVignetteWrapper
                  title={
                    <div className={styles.titleWrapperVignette}>
                      <div className={styles.titleLeft}>Top Receivers</div>
                      <div className={styles.titleRight}>
                        <a href="/#/leaderboards?type=received">View all</a>
                      </div>
                    </div>
                  }
                  subtitle={"All time"}
                >
                  {rawData && !loading ? (
                    <>
                      <Ranking
                        data={
                          rawData[INSIGHT_TYPES.USER_RANKING][
                            USER_RANKINGS.RECEIVER
                          ]
                        }
                      />
                      <div className={styles.paginate}>
                        <Pagination
                          showSizeChanger={false}
                          onChange={onChangePageReceiver}
                          current={+pageReceiver}
                          size="small"
                          defaultCurrent={
                            rawData[INSIGHT_TYPES.USER_RANKING][
                              USER_RANKINGS.RECEIVER
                            ].from /
                              RANKING_PAGE_SIZE +
                            1
                          }
                          total={
                            rawData[INSIGHT_TYPES.USER_RANKING][
                              USER_RANKINGS.RECEIVER
                            ].total
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"No claps were used yet"}
                    />
                  )}
                </DataVignetteWrapper>
                <DataVignetteWrapper
                  title={
                    <div className={styles.titleWrapperVignette}>
                      <div className={styles.titleLeft}>Top Givers</div>
                      <div className={styles.titleRight}>
                        <a href="/#/leaderboards?type=given">View all</a>
                      </div>
                    </div>
                  }
                  subtitle={"All time"}
                >
                  {rawData && !loading ? (
                    <>
                      <Ranking
                        data={
                          rawData[INSIGHT_TYPES.USER_RANKING][
                            USER_RANKINGS.GIVER
                          ]
                        }
                      />
                      <div className={styles.paginate}>
                        <Pagination
                          showSizeChanger={false}
                          current={+pageGiver}
                          onChange={onChangePageGiver}
                          size="small"
                          defaultCurrent={
                            rawData[INSIGHT_TYPES.USER_RANKING][
                              USER_RANKINGS.GIVER
                            ].from /
                              RANKING_PAGE_SIZE +
                            1
                          }
                          total={
                            rawData[INSIGHT_TYPES.USER_RANKING][
                              USER_RANKINGS.GIVER
                            ].total
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"No claps were used yet"}
                    />
                  )}
                </DataVignetteWrapper>
                {isSuperAdmin(profile) ? (
                  <>
                    <Divider />
                    <h2 className={styles.secondaryTitle}>Activity</h2>
                    <div className={styles.dataWrapper}>
                      <DataVignetteWrapper
                        additionalOption={{
                          title: "List inactive employees",
                          link: "/#/inactive-employees",
                        }}
                        title={"Active employees"}
                        infoMessage="Please note that today's data will be available tomorrow"
                        subtitle={
                          "How many employees interacted with Clappy these last 30 days"
                        }
                      >
                        <Line
                          data={activeUsers}
                          name="users"
                          width={size.width < 1080 ? size.width - 70 : 450}
                        />
                      </DataVignetteWrapper>
                      <DataVignetteWrapper
                        title={"Claps Exchanged"}
                        subtitle={
                          "How many claps were given these last 12 weeks"
                        }
                        infoMessage="Please note that this week's data will be available next week"
                      >
                        <Line
                          data={clapsExchanged}
                          name="claps"
                          width={size.width < 1080 ? size.width - 70 : 450}
                        />
                      </DataVignetteWrapper>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default Insights;
