/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/index.module.scss";
import RewardVignette from "components/rewardVignette";
import { Alert, Button, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Header from "components/header";
import ProfileContext from "components/profileContext";
import RewardListContext from "components/rewardListContext";

const Rewards = () => {
  const { profile } = useContext(ProfileContext);
  const { rewards } = useContext(RewardListContext);
  const [rewardList, setRewardList] = useState([]);
  const [archivedRewardList, setArchivedRewardList] = useState([]);

  useEffect(() => {
    if (rewards) {
      setRewardList(rewards.filter((e) => e.live));
      setArchivedRewardList(rewards.filter((e) => !e.live));
    }
  }, [profile, rewards]);

  return (
    <>
      <Header />
      <div className={styles.welcomeWrapper}>
        <PageWrapper>
          <div className={styles.welcomeInner}>
            <div className={styles.welcomeIcon}>☀️</div>
            <div>
              <h1 className={styles.welcome}>
                {`Welcome ${profile.name}`}
              </h1>
              <div className={styles.welcomeText}>
                You're about to see the list of rewards that you can reach.
                Thanks to your claps you'll be able to buy the rewards you want.
                <br />
                But remember! Sending kudos to your colleagues is what makes it
                possible. Spread positivity and positivity you will receive.
              </div>
            </div>
          </div>
        </PageWrapper>
      </div>
      <PageWrapper>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContent}>
            <h1 className={styles.title}>Rewards</h1>
            <div className={styles.subtitle}>
              Redeem rewards with your claps.
            </div>
          </div>
          <div className={styles.titleActions}>
            {profile.isAdmin && (
              <a href="/#/add-reward">
                <Button type="primary" size="large" icon={<PlusOutlined />}>
                  Add a reward
                </Button>
              </a>
            )}
          </div>
        </div>

        <div className={styles.list}>
          {rewardList && rewardList.length > 0 ? (
            rewardList.map((reward) => (
              <RewardVignette reward={reward} key={reward._id} />
            ))
          ) : (
            <div>
              <div className={styles.noarchived}>No reward to list yet.</div>
              {profile.isAdmin && (
                <div style={{marginTop:"24px"}}>
                  <Alert
                    type="info"
                    showIcon
                    message={
                      "If no rewards are defined, this page will be hidden to the employees"
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {profile.isAdmin && (
          <div>
            <Divider />
            <h2 className={styles.title}>Archived rewards</h2>
            <div className={styles.list}>
              {archivedRewardList && archivedRewardList.length > 0 ? (
                archivedRewardList.map((reward) => (
                  <RewardVignette reward={reward} key={reward._id} />
                ))
              ) : (
                <div className={styles.noarchived}>
                  No archived reward to list.
                </div>
              )}
            </div>
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default Rewards;
