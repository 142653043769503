import { Avatar } from "antd";
import ClapIcon from "components/clapIcon";
import React from "react";
import { Link } from "react-router-dom";
import styles from "styles/components/charts/ranking.module.scss";
import { numberToSpoken } from "utils/utils";

const Rank = ({ user, rank }) => (
  <div className={styles.rank}>
    <div className={styles.rankLeft}>
      <div className={styles.rankNumber}>{numberToSpoken(rank)}</div>
      <div className={styles.avatar}>
        <Avatar src={user.profilePicture} size={34} />
      </div>
      <div className={styles.name}>
        <Link to={`/users/${user._id}`}>{user.name}</Link>
      </div>
    </div>

    <div className={styles.score}>
      {user.score} <ClapIcon />
    </div>
  </div>
);
const Ranking = ({ data }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      <ul className={styles.list}>
        {data.users.map((r, index) => (
          <Rank
            user={r}
            rank={data.from + index + 1}
            key={`${r._id}${data.from}`}
          />
        ))}
      </ul>
    </div>
  </div>
);

export default Ranking;
