import { useEffect, useState } from "react";
import { API_DEV_URL, API_URL, DATE_FILTERS } from "./constants";
import moment from "moment";
import { shortnamesToUnicode } from "emojitsu";

export const getBase64 = (file, func) => {
  var filereader = new FileReader();
  filereader.readAsDataURL(file);
  filereader.onload = function (evt) {
    var base64 = evt.target.result;
    return func(base64);
  };
};

export const isLocalhost = () =>
  window && window.location && window.location.hostname === "localhost";

export const isSuperAdmin = (profile) => !profile || profile.isSuperAdmin;

export const getApiURL = () => {
  const host = window.location.host.split(".")[0];
  if (host === "app") {
    return API_URL;
  } else return API_DEV_URL;
};

export const removeYear = (date) => {
  const d = date.split("-");
  return `${d[1]}-${d[2]}`;
};

export const transformDataForLine = (data, lineName) => {
  return data.reduce(
    (acc, element) => [
      ...acc,
      {
        name: element.name,
        [lineName]: element.count,
        amt: element.count,
      },
    ],
    []
  );
};

export const transformCompanyValues = (data) => {
  const keys = Object.keys(data).filter((e) => e !== "NO_VALUE");
  return keys
    .reduce(
      (acc, element) => [
        ...acc,
        { name: shortnamesToUnicode(element), value: data[element] },
      ],
      []
    )
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      } else return 0;
    });
};

export const transformTopUsersPerCompanyValues = (data) => {
  const sorted = data.sort((a, b) => {
    if (!a.topUser) {
      return 1;
    } else if (!b.topUser) {
      return -1;
    } else return a - b;
  });
  return sorted;
};

export const transformClapsExchangedData = (data) =>
  data.reduce(
    (acc, element) => [
      ...acc,
      { ...element, name: removeYear(element.startDate) },
    ],
    []
  );
export const transformActiveUsersData = (data) =>
  data.reduce(
    (acc, element) => [...acc, { ...element, name: removeYear(element.date) }],
    []
  );

export const numberToSpoken = (number) => {
  if (typeof number !== "number") {
    throw new Error("Input must be a number");
  }

  if (number < 1) {
    throw new Error("Number must be greater than or equal to 1");
  }

  const lastDigit = number % 10;
  const secondLastDigit = Math.floor((number % 100) / 10);

  let suffix = "th";

  if (secondLastDigit !== 1) {
    if (lastDigit === 1) {
      suffix = "st";
    } else if (lastDigit === 2) {
      suffix = "nd";
    } else if (lastDigit === 3) {
      suffix = "rd";
    }
  }

  return `${number}${suffix}`;
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const getStartEndDateFromDateFilter = (dateFilter) => {
  const endDate = moment();
  let startDate = moment();
  switch (dateFilter) {
    case DATE_FILTERS.LAST_3_MONTH:
      startDate.subtract(3, "month");
      break;

    case DATE_FILTERS.LAST_7_DAYS:
      startDate.subtract(7, "days");
      break;
    case DATE_FILTERS.LAST_30_DAYS:
      startDate.subtract(30, "days");
      break;
    default:
    case DATE_FILTERS.ALL_TIMES:
      startDate.subtract(10, "year");
      break;
  }
  const params = {
    startDate: `${startDate.format("YYYY-MM-DD")}T00:00:00.000Z`,
    endDate: `${endDate.format("YYYY-MM-DD")}T23:59:59.999Z`,
  };
  return params;
};
