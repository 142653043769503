import styles from "styles/components/header.module.scss";
import Profile from "./profile";
import LogoSeed from "./logoSeed";
import { Badge, Button, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import ProfileContext from "./profileContext";
import MobileMenu from "./mobileMenu";
import { useWindowSize } from "utils/utils";
import {
  AppstoreOutlined,
  DashboardOutlined,
  DownOutlined,
  GiftOutlined,
  LogoutOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

const Header = () => {
  const location = useLocation();
  const { profile } = useContext(ProfileContext);
  const size = useWindowSize();
  const mobile = size.width < 850;

  const current = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const logout = () => {
    navigate("/logout");
  };
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <LogoSeed removeBackground />
          {!mobile && (
            <Menu
              className={styles.menu}
              selectedKeys={current}
              mode="horizontal"
              items={[
                ...(profile.isAdmin
                  ? [
                      {
                        label: (
                          <Badge count={"New"} offset={[-5, -2]} size="small">
                            <a
                              href="/#/insights"
                              className={styles.insightsLink}
                            >
                              <AppstoreOutlined /> Insights
                              <DownOutlined style={{ fontSize: "12px" }} />
                            </a>
                          </Badge>
                        ),
                        key: "insights",
                        children: [
                          {
                            icon: <DashboardOutlined />,
                            label: <a href="/#/insights">Dashboard</a>,
                            key: "dashboard",
                          },
                          {
                            label: (
                              <a
                                href="/#/leaderboards"
                              >
                                <Badge
                                  count={"New"}
                                  offset={[-8, -8]}
                                  size="small"
                                  className={styles.badge}
                                >
                                  Leaderboard
                                </Badge>
                              </a>
                            ),
                            key: "leaderboard",
                            icon: <TrophyOutlined />,
                          },
                        ],
                      },
                    ]
                  : []),
                {
                  label: (
                    <a href="/#/rewards">
                      <GiftOutlined /> Rewards
                    </a>
                  ),
                  key: "rewards",
                },
              ]}
            />
          )}
        </div>
        <div className={styles.right}>
          <Profile />
          {!mobile && (
            <Button
              onClick={logout}
              type="link"
              icon={<LogoutOutlined />}
              style={{ color: "#1f3a45" }}
            >
              Logout
            </Button>
          )}
        </div>
        {mobile && <MobileMenu logout={logout} />}
      </div>
    </header>
  );
};

export default Header;
