/* eslint-disable react-hooks/exhaustive-deps */
import LogoSeed from "components/logoSeed";
import PageWrapper from "components/pageWrapper";
import { useEffect } from "react";
import styles from "styles/pages/logout.module.scss";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("sessionId_clappy");
  }, []);
  return (
    <div>
      <PageWrapper>
        <div className={styles.content}>
          <LogoSeed />
          <h1 className={styles.title}>You've been successfuly logged out</h1>
        </div>
      </PageWrapper>
    </div>
  );
};

export default Logout;
