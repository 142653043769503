import styles from "styles/components/logoSeed.module.scss";

const LogoSeed = (
  withText = true,
  withLink = true,
  removeBackground = false
) => (
  <a href={withLink ? "/#" : ""} className={styles.logoLink}>
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img
          src={
            removeBackground
              ? "/img/logo/logo_no_background.png"
              : "/img/logo/logo.png"
          }
          alt="logo clappy kudos"
          width={30}
          height={30}
          className={styles.pic}
        />
      </div>
      {withText && <div className={styles.logoText}>Clappy Kudos</div>}
    </div>
  </a>
);

export default LogoSeed;
