import { claimReward } from "utils/api";
import ClapIcon from "./clapIcon";
import styles from "styles/components/claimButton.module.scss";
import { useContext, useState } from "react";
import ProfileContext from "./profileContext";
const { Modal, Button, Result, message } = require("antd");

const ClaimButton = ({ reward }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { profile, updateProfile } = useContext(ProfileContext);
  const disabled = profile.points < reward.amount;

  const buy = () => {
    setIsLoading(true);
    claimReward({ rewardId: reward._id })
      .then(() => {
        setTimeout(() => {
          updateProfile();
          setIsLoading(false);
          setSuccess(true);
        }, 1000);
      })
      .catch((e) => {
        message.error("Error while claiming. Try again or contact your admin.");
      });
  };

  const close = () => {
    setIsOpen(false);
    setIsLoading(false);
    setSuccess(false);
  };
  return (
    <>
      {disabled ? (
        <div className={styles.hint}>
          You don't have enough claps for that. Keep going!
        </div>
      ) : (
        <div style={{ height: "20px" }}></div>
      )}
      <Button
        disabled={disabled}
        block
        type="primary"
        className={styles.button}
        size="large"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {`${reward.amount}`}
        <ClapIcon />
      </Button>

      <Modal
        onCancel={close}
        open={isOpen}
        width={500}
        title={
          <div className={styles.modalTitle}>
            🎉 You’re about to claim a reward
          </div>
        }
        footer={
          success ? (
            <Button block onClick={close} type="primary">
              Close
            </Button>
          ) : (
            <div>
              <Button
                loading={isLoading}
                block
                type="primary"
                className={styles.button}
                size="large"
                onClick={buy}
              >
                {`${reward.amount}`}
                <ClapIcon />
              </Button>
              <div className={styles.modalHint}>
                {`After the claim, your total 👏 will be ${
                  profile.points - reward.amount
                }`}
              </div>
            </div>
          )
        }
      >
        {success ? (
          <div className={styles.modalContent}>
            <Result
              status="success"
              title="Successfully claimed"
              subTitle={reward.title}
              extra={null}
            />
          </div>
        ) : (
          <div className={styles.modalContent}>
            <div>Please confirm the reward you’re about to claim:</div>
            <div className={styles.modalNameReward}>{reward.title}</div>
            <div className={styles.process}>
              Process: An email will be sent to the reward manager, you’ll hear
              from them shortly if they have anything to validate/send/process.
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ClaimButton;
