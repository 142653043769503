import { UserOutlined } from "@ant-design/icons";
import { Avatar, Divider } from "antd";
import { useContext } from "react";
import styles from "styles/components/profile.module.scss";
import ProfileContext from "components/profileContext";
import ClapIcon from "./clapIcon";

const Profile = () => {
  const { profile } = useContext(ProfileContext);
  if (!profile || !profile.name) {
    return <></>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.clap}>
        {profile.points} <ClapIcon />
      </div>
      <Divider type="vertical" />
      <Avatar
        src={profile.profilePicture}
        className={styles.avatar}
        icon={<UserOutlined />}
        size={26}
      />
      <div className={styles.name}>{profile.name}</div>
    </div>
  );
};

export default Profile;
